import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="Footer">
      <p>2024 | &copy; JJSC HOLDINGS PTY LTD </p>
      <p>995 Kubu Street Mamelodi East, Mamelodi, Pretoria, 0122</p>
    </div>
  );
};

export default Footer;